/* Font Awesome Icons */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");

.taxonomy-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  width: 100%;
  /* margin: auto; */
  border: 1px solid #333;
  overflow-x: hidden;
}

.section1 {
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid #333;
}

.section2 {
  padding: 2px;
  width: fit-content;
}

.section3 {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-top: 1px solid #333;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 0px;
}

.search-container input[type="text"] {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.search-button {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 3px;
}

.checkbox-container {
  max-height: 300px;
  overflow-y: auto;
  max-width: fit-content;
  margin-left: 0px;
  padding-left: 0px;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 5px;
  list-style-type: none;
  margin-left: 0px;
}

/* Style for checkboxes */
.checkbox-container input[type="checkbox"] + label {
  /* margin-left: 5px; */
  list-style-type: none;
  font-style: italic;
}
.apply-button {
  margin-left: 0.5rem;
  background-color: #007bff;
  padding: 5px 10px;
  border: 0px;
  border-radius: 5px;
  font-size: larger;
  font-style: bold;
  color: #fff;
  cursor: pointer;
}

/* .apply-button:disabled{
    background-color: #7aaefc;
    color: #fff;
    cursor: not-allowed;

} */

.cancel-button {
  margin-left: 0.5rem;
  background-color: #fff;
  padding: 5px 10px;
  border: 0px;
  border-radius: 5px;
  font-size: larger;
  font-style: bold;
  color: #222;
  cursor: pointer;
}

li {
  list-style-type: none;
}
